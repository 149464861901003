import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApisService } from 'src/app/services/apis.service';

@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.css']
})
export class ModalsComponent implements OnInit {

  @ViewChild( "showModale") showModale;
  @ViewChild("closeButton") closeButton;
  errorMessage :string;
  constructor(private http :ApisService) { }
  @Output() childAdded: EventEmitter<string> =   new EventEmitter();


  ngOnInit(): void {
  }

  addUser(body :NgForm){
    this.errorMessage =""
      this.http.addUser(body).subscribe(response=>{ 
            if(response.status == 1 ){
              this.closeButton.nativeElement.click();
                this.childAdded.emit('user Added Successfully');
                this.removeFaild()
            }
            else{
              this.errorMessage ='* '+ response.message;
            }
      })
  }
  removeFaild(){

  }
}
