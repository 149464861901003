import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LeftSidebarComponent } from './components/left-sidebar/left-sidebar.component';
import { TopbarComponent } from './components/topbar/topbar.component';
import { UsersComponent } from './components/users/users.component';
import { ModalsComponent } from './components/modals/modals.component';
import { NotificationComponent } from './components/notification/notification.component';
import { SingleUserComponent } from './components/single-user/single-user.component';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/home/home.component';
import { FormsModule }   from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { EditModalComponent } from './components/edit-modal/edit-modal.component';
import { SettingsComponent } from './components/settings/settings.component';

@NgModule({
  declarations: [
    AppComponent,
    LeftSidebarComponent,
    TopbarComponent,
    UsersComponent,
    ModalsComponent,
    NotificationComponent,
    SingleUserComponent,
    LoginComponent,
    HomeComponent,
    EditModalComponent,
    SettingsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
