import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { ApisService } from 'src/app/services/apis.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.css']
})
export class NotificationComponent implements OnInit {

  @ViewChild( "sendModale") sendModale;
  @ViewChild("closeButton") closeButton;
  errorMessage :string;
  @Input() ids : number[] ;
  @Output() childNotification: EventEmitter<string> =   new EventEmitter();
  constructor(private api : ApisService ,private router: Router) {
    let token = localStorage.getItem('token');
    if(token == null){
      this.router.navigate(['/login']);
    }
   }
  ngOnInit(): void {
    
  }
  sendNotification(body : NgForm){
    this.errorMessage=""
    this.api.sendNotification(body).subscribe(response =>{
      if(response.status == 1){
        this.closeButton.nativeElement.click();
        this.childNotification.emit('Notification Sent Successfully');
      }
      else{
        this.errorMessage ='* '+ response.message;
      }
    })
  }

}
