import { Component, OnInit } from '@angular/core';
import { ApisService } from 'src/app/services/apis.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  errorMessage :string
  constructor(private router: Router,private services :ApisService) { }

  ngOnInit(): void {
  }


   submitLogin(data :NgForm){


    this.services.login(data).subscribe(response =>{
      this.errorMessage =""
      if(response.status == 1){

        localStorage.setItem('id',response.data.id +"");
        localStorage.setItem('token',response.data.token);
        localStorage.setItem('name',response.data.name);
        localStorage.setItem('email',response.data.email);
       this.router.navigate(['/users']);
       
        
      }   else{
        this.errorMessage ="* "+ response.message;
      }
      

    },error => {
    });
  }
}
