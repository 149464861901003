import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { UsersComponent } from './components/users/users.component';
import { NotificationComponent } from './components/notification/notification.component';
import { LoginComponent } from './components/login/login.component';
import { SettingsComponent } from './components/settings/settings.component';


const routes: Routes = [
  { path: 'users', component: UsersComponent },
  { path: 'notification', component: NotificationComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'login', component: LoginComponent },
  {path: '', redirectTo: '/login',pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(
    routes,
    { scrollPositionRestoration :'top'},
    
    )],
  exports: [RouterModule]
})
export class AppRoutingModule { }
