<tr>
    <th>1</th>
    <th>GOOG Google Inc.</th>
    <td>597.74</td>
    <td>12:12PM</td>
    <td>14.81 (2.54%)</td>
    <td>
        <ul class="StatusIcon">
            <li class="edit"><i class="fa fa-edit"></i></li>
            <li class="show"><i class="fa fa-eye"></i></li>
            <li class="delete" id="sa-warning"><i class="fa fa-trash"></i></li>
        </ul>
    </td>
    
</tr>