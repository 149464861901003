    <!-- Start Page content -->
    <div class="content">
        <div class="container-fluid">

            <div class="row">
                <div class="col-12">
                    <div class="card-box">
                        <div class="topHeader">
                            <h3>Users
                                <span class="buttonModale">
                                    <app-notification [ids]='ids' (childNotification)="onNotificationSend($event)"></app-notification>
                                    <app-modals (childAdded)="onChildAdded($event)"></app-modals>
                                </span>
                            </h3>
                        </div>
                        <div class="table-rep-plugin">
                            <div class="table-responsive" data-pattern="priority-columns">
                                <table id="tech-companies-1" class="table  table-striped">
                                    <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>select</th>
                                        <th>User Name</th>
                                        <th >Company Name</th>
                                        <th>Phone</th>
                                        <th >Email</th>
                                        <th>Status</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr *ngFor='let item of users'>
                                        <th>{{item.id}}</th>
                                        <th><input type="checkbox" #checkboxes type="checkbox" name="chek" data-md-icheck ngModel (ngModelChange)="selectItem($event,item)"></th>
                                        <th>{{item.user_name}}</th>
                                        <td>{{item.company_name}}</td>
                                        <td>{{item.phone}}</td>
                                        <td>{{item.email}}</td>
                                        
                                        <td>
                                            <ul class="StatusIcon">
                                                <li class="edit"><app-edit-modal [user]=item (childEdited)='onChildAdded($event)'></app-edit-modal></li>
                                                <li class="delete" (click)='clickSweet(item.id)'><i class="fa fa-trash"></i></li>
                                            </ul>
                                        </td>
                                        
                                    </tr>

                                    </tbody>
                                </table>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <!-- end row -->

        </div> <!-- container -->

    </div> <!-- content -->


